









import { MinMaxTelemetryIndex } from '@/types/telemetry';
import { Component, Vue, Prop } from 'vue-property-decorator';
import SemiCircleGauge from '../../dataDisplayComponents/SemiCircleGauge.vue';

@Component({
  components: {
    SemiCircleGauge
  }
})
/**
 * @group Analytics Card
 * Card with semi circle gauges for displaying index values withing min and max ranges
 */
export default class LampVe1V1ControllerTelemetryCard extends Vue {
  @Prop({ type: Array, required: true }) readonly telemetries!: MinMaxTelemetryIndex[]
}
